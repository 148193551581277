<template>
  
    
      <BodySystem></BodySystem>
    
</template>

<script>

import BodySystem from '../components/BodySystem/BodySystem.vue';

export default {
  
  name: 'HomePage',
  components: {
    BodySystem,
  },

  data() {
   
  }, 
}
</script>
